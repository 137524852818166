import { HdeSchemas } from '@api/types/generated/apiSchemas';
import { OddsBreakdown } from '@api/types/temporaryOddsReport';

type Breakdowns = HdeSchemas['Breakdowns'];

const adaptOddsBreakdown = (report: Breakdowns): OddsBreakdown => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, breakdowns, racecourse, ...reportProps } = report;

  return {
    ...reportProps,
    racecourseCode: racecourse?.code,
    breakdowns: breakdowns.map(({ combination = [], odds = null, ...datum }) => ({
      ...datum,
      combination,
      odds,
    })),
  };
};

const adaptOddsBreakdowns = (breakdowns: Breakdowns[]): OddsBreakdown[] =>
  breakdowns.map(adaptOddsBreakdown);

export { adaptOddsBreakdowns };
