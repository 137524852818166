import { useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';

import {
  CookiesBlockType,
  CookiesConfig,
  CookiesConfigItem,
  CookiesToggle,
  localStorageCookieConsent,
  scriptCookieId,
} from '@components/CookiesModal/config';
import { cookieConfirmEvent, subscribe, unsubscribe, publish } from '@utils/event';

const useCookies = (cookiesConfig: CookiesConfig) => {
  const dispatchScriptTag = useCallback((cookieName: string, accept: boolean) => {
    const elements = document.querySelectorAll<HTMLScriptElement>(
      `[${scriptCookieId}="${cookieName}"]`
    );
    const type = accept ? 'text/javascript' : 'text/plain';
    elements.forEach((element: HTMLScriptElement) => {
      const newScript = document.createElement('script');
      newScript.type = type;
      newScript.setAttribute(scriptCookieId, cookieName);
      if (element.src) {
        newScript.src = element.src;
      } else {
        newScript.innerHTML = element.innerHTML;
      }
      element.remove();
      document.body.appendChild(newScript);
    });
  }, []);

  const dispatchWrapper = useCallback((cookieName: string, accept: boolean) => {
    publish(`cookie-${cookieName}`, accept);
  }, []);

  const removeCookies = useCallback((config: CookiesConfigItem) => {
    const cookies = Cookies.get();
    Object.keys(cookies).forEach((cookie) => {
      if (config.cookieRegex?.test(cookie)) {
        Cookies.remove(cookie);
      }
    });
  }, []);

  useEffect(() => {
    const listener = (e: CustomEvent<CookiesToggle>) => {
      const cookiesToggle = e.detail;
      localStorage.setItem(localStorageCookieConsent, JSON.stringify(cookiesToggle));
      Object.keys(cookiesToggle).forEach((cookieName) => {
        const config = cookiesConfig[cookieName];
        if (config.type === CookiesBlockType.SCRIPT_TAG) {
          dispatchScriptTag(cookieName, cookiesToggle[cookieName]);
        } else if (config.type === CookiesBlockType.WRAPPER) {
          dispatchWrapper(cookieName, cookiesToggle[cookieName]);
        }

        if (!cookiesToggle[cookieName]) {
          removeCookies(config);
        }
      });
    };
    subscribe(cookieConfirmEvent, listener);

    return () => {
      unsubscribe(cookieConfirmEvent, listener);
    };
  }, []);

  useEffect(() => {
    const cookiesData = localStorage.getItem(localStorageCookieConsent);
    if (cookiesData) {
      const objectCookieData = JSON.parse(cookiesData);
      const sameConfig = Object.keys(cookiesConfig).length === Object.keys(objectCookieData).length;
      if (sameConfig) {
        publish(cookieConfirmEvent, objectCookieData);
      }
    }
  }, []);
};

export { useCookies };
