import type { Race } from '../../types';
import type { ApiRace } from '../../types/apiRace';

const adaptRanking = (ranking: ApiRace['ranking']): Race['ranking'] => {
  if (!ranking?.length) {
    return [];
  }

  type RankingItem = typeof ranking[number];

  function hasRankNumber(r: RankingItem): r is Omit<RankingItem, 'rank'> & {
    rank: number;
  } {
    return r.rank !== null;
  }

  const sortedRacesByRank = ranking.filter(hasRankNumber);

  if (!sortedRacesByRank.length) {
    return [];
  }

  const ranks = sortedRacesByRank.map((arrival) => arrival.rank).sort((a, b) => a - b);

  // Get uniques ranks ([1, 2, 3, 4, 5, ...])
  const uniquesRanks = [...new Set(ranks)];

  const order = uniquesRanks.map((rank) => {
    // Get all runnerNumbers from current rank
    const matchedRunnerNumbers = sortedRacesByRank
      .filter((arrival) => arrival.rank === rank)
      .map((arrival) => arrival.runnerNumber);

    // If matched array length is greater than 1, we have exaequo ([10, 4, 8, ...])
    return matchedRunnerNumbers.length > 1 ? matchedRunnerNumbers : matchedRunnerNumbers[0];
  });

  return order;
};

export { adaptRanking };
