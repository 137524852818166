import { REDIRECT_URL_LS_KEY } from './constants';

interface UseGetRedirectionUrlReturn {
  getRedirectUrl: () => string | null;
}

export const useRedirectUrl = (): UseGetRedirectionUrlReturn => {
  const getRedirectUrl = () => {
    const value = window.localStorage.getItem(REDIRECT_URL_LS_KEY);

    // cela permet de ne pas perdre la ref immediatement
    // ainsi, la suppression passe dans la prochaine render loop
    setTimeout(() => {
      window.localStorage.removeItem(REDIRECT_URL_LS_KEY);
    });

    return value;
  };

  return { getRedirectUrl };
};
