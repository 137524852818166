import { useEffect } from 'react';

import { partnersApi } from '@api/services/partners';
import { socket } from '@api/socket';

export type UseWebSocketReturn = {
  disconnect: VoidFunction;
};

const useWebSocket = (): UseWebSocketReturn => {
  const [trigger] = partnersApi.endpoints.getPartners.useLazyQuery();
  // const { resetApiStates } = useResetApiStates();

  const connect = async () => {
    try {
      const partner = await trigger().unwrap();

      if (partner.id && !socket.isConnected()) {
        socket.connect(partner.id);

        // Deactivated because rerender on refresh https://pmutech.atlassian.net/browse/PB-1278
        // Force clear all caches from rtk-query api calls to re-fetch up-to-date data automatically
        // socket.onReconnect(resetApiStates);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('WS:::useWebSocket - getPartners', error);
    }
  };

  const disconnect = () => {
    socket.close();
  };

  useEffect(() => {
    connect();

    return () => {
      disconnect();
    };
  }, []);

  return {
    disconnect,
  };
};

export { useWebSocket };
