/* eslint-disable @typescript-eslint/no-unused-vars */

import { TornadoTypes } from '@pmu/tornado';

import { isNonRunner } from '@utils/isNonRunner';

import { ApiRunner } from '../types/apiRunner';

import { adaptBlinkers } from './runners/blinckers';
import { adaptHorse } from './runners/horse';
import { adaptShoes } from './runners/shoes';

import type { Runner } from '../types';

const adaptRunner = (runner: ApiRunner): Runner => {
  const {
    claimRate,
    finishComment,
    racingStable,
    railRequested,
    blinkers,
    shoes,
    horse,
    spread,
    trainer,
    owner,
    rider,
    trainingCountry,
    ...otherProps
  } = runner;

  return {
    ...otherProps,
    blinkers: adaptBlinkers(blinkers),
    shoes: adaptShoes(shoes),
    notStarting: isNonRunner(runner.status.code),
    horse: adaptHorse(horse),
    spread: spread?.label ?? null,
    trainer: trainer?.name ?? null,
    owner: owner?.name ?? null,
    rider: rider?.name ?? null,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    trainingCountry: (trainingCountry?.code as TornadoTypes.IsoCode) ?? null,
  };
};

const adaptRunners = (runners: ApiRunner[]): Runner[] => runners.map(adaptRunner);

export { adaptRunners };
