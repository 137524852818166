import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  MenuItem,
  MenuItemLabel,
  MenuSeparator,
  MenuTrigger,
  Profile,
  SimpleMenu,
  Text,
} from '@pmu/tornado';

import { getUser, userApi } from '@api/services';
import { GrantAccess } from '@components/GrantAccess/GrantAccess';
import { ROUTES } from '@config/routes';
import { useWebSocket } from '@hooks/useWebSocket';
import { extractUserData } from '@services/helpers';
import { sendTag } from '@services/SendTag';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import type { User } from '@api/types';

import './index.scss';

const ID_ACCOUNT = 'account';
const ID_SUBSCIPTIONS = 'subscriptions';
const ID_LOGOUT = 'logout';

const ProfileMenu = () => {
  const socket = useWebSocket();
  const translations = useIntl();
  const user = useAppSelector<User>(getUser as () => User);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const menuItemLabels = {
    [ID_ACCOUNT]: translations.formatMessage({ id: 'app.user.menu.entries.account' }),
    [ID_SUBSCIPTIONS]: translations.formatMessage({ id: 'app.user.menu.entries.subscriptions' }),
    [ID_LOGOUT]: translations.formatMessage({ id: 'app.user.menu.entries.logout' }),
  };

  const onMenuClick = async (id: string) => {
    if (id === ID_LOGOUT) {
      sendTag('infocentre.clic.header.compte.deconnexion', extractUserData(user));

      try {
        const redirectionUrl = await dispatch(userApi.endpoints.logoutUser.initiate()).unwrap();

        if (redirectionUrl) {
          socket.disconnect();
          // Need to replace the current location here to avoid to return on the previous connected page from Infocentre on back button
          window.location.replace(redirectionUrl);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('ProfileMenu:::logout item - get logout', error);
      }
    }

    // We use navigate instead of <Link /> because it's causing not auto-hide of SimpleMenu
    if (id === ID_ACCOUNT) {
      sendTag('infocentre.clic.header.compte.mon_compte', extractUserData(user));
      navigate(ROUTES.ACCOUNT);
    }

    if (id === ID_SUBSCIPTIONS) {
      sendTag('infocentre.clic.header.compte.mes_abonnements', extractUserData(user));
    }
  };

  return (
    <SimpleMenu
      sameWidth
      gutter={10}
      trigger={
        <MenuTrigger
          className="ProfileMenu-trigger"
          onClick={() => {
            sendTag('infocentre.clic.header.acces_compte', extractUserData(user));
          }}
        >
          <Text type="regular" size="2m" className="ProfileMenu-trigger-userName" color="gray-700">
            {`${user.firstName} ${user.lastName}`}
          </Text>
          <Profile iconStyle="ProfileMenu-trigger-icon" />
        </MenuTrigger>
      }
      onClick={onMenuClick}
    >
      <GrantAccess resourceId="app.layout.profileMenu.item.account">
        <MenuItem id={ID_ACCOUNT} className="ProfileMenu-item">
          <MenuItemLabel label={menuItemLabels[ID_ACCOUNT]} />
        </MenuItem>
      </GrantAccess>
      <MenuSeparator />
      <GrantAccess resourceId="app.layout.profileMenu.item.subscriptions">
        <MenuItem id={ID_SUBSCIPTIONS} className="ProfileMenu-item">
          <MenuItemLabel label={menuItemLabels[ID_SUBSCIPTIONS]} />
        </MenuItem>
      </GrantAccess>
      <MenuSeparator />
      <MenuItem id={ID_LOGOUT} className="ProfileMenu-item">
        <MenuItemLabel label={menuItemLabels[ID_LOGOUT]} />
      </MenuItem>
    </SimpleMenu>
  );
};

export { ProfileMenu };
