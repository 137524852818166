import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryCacheLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import microdiff from 'microdiff';

import { adaptRace } from '@api/adapters/races';
import { socket } from '@api/socket';
import { Race } from '@api/types';
import { updateRaceEvent } from '@store/slices/events';

import { RaceArgs } from '../../race';
import { RacesArgs } from '../../races';
import { EventType, RaceEvent } from '../types/Event';

import type { messageCallbackType } from '@stomp/stompjs';

export type OnRacesCacheEntryAdded = QueryCacheLifecycleApi<
  RacesArgs,
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
  Race[],
  'racesApi'
>;

export type OnRaceCacheEntryAdded = QueryCacheLifecycleApi<
  RaceArgs,
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
  Race,
  'raceApi'
>;

export const isRace = (wsEvent: { type: EventType }): wsEvent is RaceEvent => {
  return wsEvent.type === 'RACE';
};

const onRacesCacheEntryAdded = async (
  arg: RacesArgs,
  {
    updateCachedData,
    cacheDataLoaded,
    cacheEntryRemoved,
    dispatch,
    getCacheEntry,
  }: OnRacesCacheEntryAdded
) => {
  let unsubscribe;
  try {
    // wait for the initial query to resolve before proceeding
    await cacheDataLoaded;
    // when data is received from the socket connection to the server,
    // if it is a message and for the appropriate channel,
    // update our query result with the received message
    const callback: messageCallbackType = (message) => {
      const actualCacheData = getCacheEntry().data ?? [];
      const data = JSON.parse(message.body);

      // we only want to update the cache if the message is a meeting event
      const isRaceEvent = isRace(data);
      const { id: eventRaceId, date: eventRaceDate, racecourse } = JSON.parse(data.text);
      const racecourseCode = racecourse?.code;
      const iAmConcerned = arg.startDate === eventRaceDate && arg.racecourseCode === racecourseCode;

      if (!isRaceEvent || typeof data.text !== 'string' || !iAmConcerned) {
        return;
      }

      // we check if the race is already in the cache
      const matchedStoredRaceIndex = actualCacheData.findIndex(
        (storedRace) => storedRace.id === eventRaceId
      );
      // as of now backend send us a race event with 'runners' extra property that we do not want to add to our current cache entry
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { runners, ...raceWithoutRunners } = JSON.parse(data.text);
      const adaptedEventRace = adaptRace(raceWithoutRunners);
      // we add the new race to the cache if it is not already in it
      // otherwise we update the race in the cache
      let newCacheData: Race[];

      if (matchedStoredRaceIndex === -1) {
        newCacheData = [...actualCacheData, adaptedEventRace];
      } else {
        newCacheData = actualCacheData.map((storedRace, index) =>
          index === matchedStoredRaceIndex ? adaptedEventRace : storedRace
        );
      }
      // we get the diff between the new race and the old one
      // if the race was not in the cache, the diff will be the whole race
      const diff = microdiff(actualCacheData[matchedStoredRaceIndex] || {}, adaptedEventRace);
      // we update the cache with the computed data
      updateCachedData(() => newCacheData);
      // we dispatch an action to update the updateRaceEvent slice with the diff
      dispatch(
        updateRaceEvent({
          id: eventRaceId,
          diff,
        })
      );
    };
    unsubscribe = socket.subscribe(callback);
  } catch (error) {
    // Handle errors here
    // eslint-disable-next-line no-console
    console.error('Error in socket subscription:', error);
  }
  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;
  // perform cleanup steps once the `cacheEntryRemoved` promise resolves
  // Run cleanup for previous listener here
  unsubscribe?.();
};

const onRaceCacheEntryAdded = async (
  arg: RaceArgs,
  {
    updateCachedData,
    cacheDataLoaded,
    cacheEntryRemoved,
    dispatch,
    getCacheEntry,
  }: OnRaceCacheEntryAdded
) => {
  let unsubscribe;

  try {
    // wait for the initial query to resolve before proceeding
    await cacheDataLoaded;

    // when data is received from the socket connection to the server,
    // if it is a message and for the appropriate channel,
    // update our query result with the received message

    const callback: messageCallbackType = (message) => {
      const actualCacheData = getCacheEntry().data || {};
      const data = JSON.parse(message.body);

      // we only want to update the cache if the message is a race event
      const isRaceEvent = isRace(data);

      if (!isRaceEvent || typeof data.text !== 'string') {
        return;
      }

      const {
        id: eventRaceId,
        date: eventRaceDate,
        raceNumber,
        racecourse,
      } = JSON.parse(data.text);

      const racecourseCode = racecourse?.code;

      const iAmConcerned =
        arg.date === eventRaceDate &&
        arg.racecourseCode === racecourseCode &&
        arg.raceNumber === raceNumber;

      if (!iAmConcerned) {
        return;
      }

      // as of now backend send us a race event with 'runners' extra property that we do not want to add to our current cache entry
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { runners, ...raceWithoutRunners } = JSON.parse(data.text);
      const adaptedEventRace = adaptRace(raceWithoutRunners);

      // we get the diff between the new race and the old one
      // if the race was not in the cache, the diff will be the whole race
      const diff = microdiff(actualCacheData, adaptedEventRace);

      // we update the cache with the computed data
      updateCachedData(() => adaptedEventRace);

      // we dispatch an action to update the updateRaceEvent slice with the diff
      dispatch(
        updateRaceEvent({
          id: eventRaceId,
          diff,
        })
      );
    };

    unsubscribe = socket.subscribe(callback);
  } catch {
    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }
  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;
  // perform cleanup steps once the `cacheEntryRemoved` promise resolves
  // Run cleanup for previous listener here
  unsubscribe?.();
};

export { onRacesCacheEntryAdded, onRaceCacheEntryAdded };
