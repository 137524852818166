import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { getUser } from '@api/services';
import { useRedirectUrl } from '@hooks/useRedirectUrl';
import { useAppSelector } from '@store/hooks';

import { ROUTES } from '../../config/routes';

const Private = ({ children }: { children: JSX.Element }) => {
  const user = useAppSelector(getUser);
  const { getRedirectUrl } = useRedirectUrl();

  const location = useLocation();

  const isCGUValidationURL = location.pathname === ROUTES.CGU_VALIDATION;

  if (!user) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }

  if (!user.cgu && !isCGUValidationURL) {
    return <Navigate to={ROUTES.CGU_VALIDATION} state={{ from: location }} replace />;
  }

  if (user.cgu && isCGUValidationURL) {
    return <Navigate to={ROUTES.HOME} state={{ from: location }} replace />;
  }

  const redirectUrl = getRedirectUrl();

  if (redirectUrl) {
    return <Navigate to={redirectUrl} replace />;
  }

  return children;
};

export { Private };
