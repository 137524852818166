import React from 'react';
import { Calendar, Form, Pronostics, Text, TranslatedText } from '@pmu/tornado';

import { getUser } from '@api/services';
import { User } from '@api/types';
import { OLD_INFOCENTRE_URL } from '@config/urls';
import { extractUserData } from '@services/helpers';
import { sendTag } from '@services/SendTag';
import { useAppSelector } from '@store/hooks';
import './index.scss';

type Icon = typeof Calendar | typeof Pronostics | typeof Form;

const getIconComponent = (idLabel: string): Icon => {
  switch (idLabel) {
    case 'meetingsCalendar':
      return Calendar;
    case 'pronostics':
      return Pronostics;
    default:
      return Form;
  }
};

const tags = {
  meetingsCalendar: 'infocentre.clic.sidebar.calendrier_reunion',
  pronostics: 'infocentre.clic.sidebar.pronostics',
  publications: 'infocentre.clic.sidebar.publications',
};

const SidebarMenuItemExternal = ({
  idLabel,
}: {
  idLabel: 'meetingsCalendar' | 'pronostics' | 'publications';
}) => {
  const user = useAppSelector<User>(getUser as () => User);

  const openExternalURL = () => {
    window.open(OLD_INFOCENTRE_URL, '_blank', 'noopener noreferrer');
    sendTag(tags[idLabel], extractUserData(user));
  };

  const IconComponent = getIconComponent(idLabel);

  return (
    <button
      type="button"
      className="layout-sideBarMenu-SidebarMenuItemExternal-content"
      onClick={openExternalURL}
    >
      <IconComponent
        iconStyle="layout-sideBarMenu-SidebarMenuItemExternal-content-icon"
        color="gray-500"
      />
      <span>
        <Text type="bold" color="gray-700">
          <TranslatedText id={`app.categories.${idLabel}`} />
        </Text>
      </span>
    </button>
  );
};

export { SidebarMenuItemExternal };
