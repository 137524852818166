import React from 'react';
import { Outlet } from 'react-router-dom';
import classnames from 'classnames';

import { useMediaQuery } from '@hooks/useMediaQuery';

import { useLayout } from '../Common';

import './index.scss';

const AccountLayout = () => {
  const { noSidebar } = useLayout('Account');
  const { atMost } = useMediaQuery();

  const classNames = classnames('Account', {
    'is-folded': !atMost && noSidebar,
  });

  return (
    <section className={classNames}>
      <Outlet />
    </section>
  );
};

export { AccountLayout };
