import { Race } from '@api/types';

import type { Status } from '@components/RaceStatus';

const adaptRaceStatus = (raceStatusCode: Race['raceStatus']): Status | null => {
  switch (raceStatusCode) {
    case '1':
      return 'stopped';
    case '8':
      return 'ongoing';
    case '10':
    case '11':
    case '12':
    case '13':
    case '14':
    case '15':
    case '16':
      return 'ended';
    case '17':
      return 'cancelled';
    default:
      return 'upcoming';
  }
};

export { adaptRaceStatus, type Status };
