import React from 'react';
import { Outlet } from 'react-router-dom';

import { useLayout } from '../Common';

import './index.scss';

const AppLayout = () => {
  useLayout('App');

  return (
    <section className="App">
      <Outlet />
    </section>
  );
};

export { AppLayout };
