import { Runner } from '@api/types';
import { ApiRunner } from '@api/types/apiRunner';

const adaptShoes = (shoes: ApiRunner['shoes']): Runner['shoes'] => {
  const value = Number(shoes?.code);

  return Number.isNaN(value) || value < 0 || value > 8 ? null : (value as Runner['shoes']);
};

export { adaptShoes };
