import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import microdiff from 'microdiff';

export type Difference = ReturnType<typeof microdiff>[0];
export type EventsState = Record<string, Difference[]>;

const initialState: EventsState = {};

const sliceCreator = (name: string) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      update: (state, { payload }: PayloadAction<{ id: string; diff: Difference[] }>) => {
        if (!(payload.id in state)) {
          // eslint-disable-next-line no-param-reassign
          state[payload.id] = payload.diff;
        } else {
          state[payload.id].push(...payload.diff);
        }
      },
    },
  });
};

export { sliceCreator };
