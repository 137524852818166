import { getUser } from '@api/services/user';
import { User } from '@api/types/user/user';
import { ProtectedResourceId } from '@api/types/user/userAccessRights';
import { hasEnoughRights } from '@api/types/user/userRole';
import { useAppSelector } from '@store/hooks';

export type UseGrantAccessProps = {
  resourceId: ProtectedResourceId;
};

export const useGrantAccess = ({ resourceId }: UseGrantAccessProps): boolean => {
  const user = useAppSelector<User | undefined>(getUser);

  if (!user) {
    return false;
  }

  const canDisplay = hasEnoughRights(user, resourceId);
  if (!canDisplay) {
    return false;
  }

  return true;
};
