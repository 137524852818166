import { Runner } from '@api/types';
import { ApiRunner } from '@api/types/apiRunner';

const adaptBlinkers = (blinckers: ApiRunner['blinkers']): Runner['blinkers'] => {
  switch (blinckers?.code) {
    case 'A':
    case 'Y':
    case 'N':
      return blinckers.code;
    default:
      return null;
  }
};

export { adaptBlinkers };
