import React, { useEffect, useState } from 'react';
import { Dialog, Modal } from 'react-aria-components';
import './index.scss';
import { Toast, TranslatedText, Text } from '@pmu/tornado';

import {
  CookiesConfig,
  CookiesToggle,
  localStorageCookieConsent,
} from '@components/CookiesModal/config';
import { cookieConfirmEvent, publish } from '@utils/event';

import { ConfigTab } from './ConfigTab';
import { InitialTab } from './InitialTab';

// eslint-disable-next-line no-shadow
export enum CookiesModalTab {
  INITIAL = 'initial',
  CONFIG = 'config',
}

interface CookiesDialogProps {
  cookiesConfig: CookiesConfig;
}

const CookiesDialog = ({ cookiesConfig }: CookiesDialogProps) => {
  const [open, setOpen] = useState(!localStorage.getItem(localStorageCookieConsent));
  const [selectedTab, setSelectedTab] = useState<CookiesModalTab>(CookiesModalTab.INITIAL);
  const [toastVisible, setToastVisible] = useState(false);

  const handleChangeTab = (tab: CookiesModalTab) => {
    setSelectedTab(tab);
  };

  const handleConfirm = (data: CookiesToggle) => {
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 4000);
    publish(cookieConfirmEvent, data);
    setOpen(false);
  };

  useEffect(() => {
    const cookiesData = localStorage.getItem(localStorageCookieConsent);
    if (cookiesData) {
      const objectCookieData = JSON.parse(cookiesData);
      const sameConfig = Object.keys(cookiesConfig).length === Object.keys(objectCookieData).length;
      if (sameConfig) {
        setOpen(false);
      } else {
        localStorage.setItem(localStorageCookieConsent, '');
        setOpen(true);
      }
    } else {
      setOpen(true);
    }
  }, []);

  if (!open && !toastVisible) {
    return null;
  }

  return (
    <>
      <Toast toastVisible={toastVisible}>
        <Text type="bold" size="m">
          <TranslatedText id="app.cookies.confirm" />
        </Text>
      </Toast>
      <Modal isOpen={open}>
        <Dialog className="CookiesDialog">
          {() => (
            <>
              {selectedTab === CookiesModalTab.INITIAL ? (
                <InitialTab
                  onChangeTab={handleChangeTab}
                  config={cookiesConfig}
                  onConfirm={handleConfirm}
                />
              ) : null}
              {selectedTab === CookiesModalTab.CONFIG ? (
                <ConfigTab
                  config={cookiesConfig}
                  onChangeTab={handleChangeTab}
                  onConfirm={handleConfirm}
                />
              ) : null}
            </>
          )}
        </Dialog>
      </Modal>
    </>
  );
};

export { CookiesDialog };
