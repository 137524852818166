import {
  ProtectedResourceId,
  UserAccessRights,
  extendRights,
} from '@api/types/user/userAccessRights';

import { User } from './user';

type UserRolePmu = 'ROLE_SUPERVISOR' | 'ROLE_ADMIN_INFOCENTRE' | 'ROLE_CONSULTATION';
type UserRolePartenaires = 'ROLE_OPERATOR' | 'ROLE_RACING_MANAGER' | 'ROLE_DEVELOPER';

export type UserRoleKind = User['role'];
export type HasEnoughRights = (user: User, resourceId: ProtectedResourceId) => boolean;

export interface UserRole {
  kind: UserRoleKind;
  rights: UserAccessRights;
}

export class UserRights {
  // Ici le role unknown est interne au back mais peut nous le renvoyer.
  // Nous devons donc le traiter et comme on ne connait pas, il n'a droit à rien du tout
  public static readonly ROLE_UNKNOWN: UserAccessRights = {
    'app.layout.profileMenu.item.account': false,
    'app.layout.profileMenu.item.subscriptions': false,
    'app.fiches.account.corporatePortal': false,
    'app.fiches.account.accessPermissions': false,
    'app.fiches.racecards.example': false,
    'app.route.login': false,
    'app.route.account': false,
    'app.route.home': false,
    'app.route.racecards': false,
    'app.route.meetings': false,
    'app.route.nonRunners': false,
    'app.route.racingEvents': false,
    'app.route.races': false,
    'app.route.quinte': false,
    'app.layout.sidebar': false,
  };

  public static readonly ROLE_CONSULTATION = extendRights(UserRights.ROLE_UNKNOWN, {
    'app.layout.profileMenu.item.account': true,
    'app.layout.profileMenu.item.subscriptions': true,
    'app.fiches.account.corporatePortal': false,
    'app.fiches.account.accessPermissions': false,
    'app.route.login': true,
    'app.route.account': true,
    'app.route.home': true,
    'app.route.racecards': true,
    'app.route.nonRunners': true,
    'app.route.racingEvents': true,
    'app.route.meetings': true,
    'app.route.races': true,
    'app.route.quinte': true,
    'app.layout.sidebar': true,
  });

  public static readonly ROLE_ADMIN_INFOCENTRE = extendRights(UserRights.ROLE_CONSULTATION, {});

  public static readonly ROLE_SUPERVISOR = extendRights(UserRights.ROLE_ADMIN_INFOCENTRE, {});

  public static readonly ROLE_OPERATOR = extendRights(UserRights.ROLE_UNKNOWN, {
    'app.layout.profileMenu.item.account': true,
    'app.layout.profileMenu.item.subscriptions': true,
    'app.fiches.account.corporatePortal': true,
    'app.fiches.account.accessPermissions': true,
    'app.route.login': true,
    'app.route.account': true,
    'app.route.home': true,
    'app.route.racecards': true,
    'app.route.nonRunners': true,
    'app.route.racingEvents': true,
    'app.route.meetings': true,
    'app.route.races': true,
    'app.route.quinte': true,
    'app.layout.sidebar': true,
  });

  public static readonly ROLE_RACING_MANAGER = extendRights(UserRights.ROLE_OPERATOR, {});

  public static readonly ROLE_DEVELOPER = extendRights(UserRights.ROLE_RACING_MANAGER, {
    'app.layout.profileMenu.item.account': false,
    'app.layout.profileMenu.item.subscriptions': false,
    'app.route.home': false,
    'app.route.racecards': false,
    'app.route.nonRunners': false,
    'app.route.racingEvents': false,
    'app.route.meetings': false,
    'app.route.races': false,
    'app.route.quinte': false,
    'app.fiches.racecards.example': true,
    'app.layout.sidebar': false,
  });

  public static rights = (role: UserRoleKind): UserAccessRights => {
    return UserRights[role];
  };
}

export const hasEnoughRights: HasEnoughRights = (
  user: User,
  resourceId: ProtectedResourceId
): boolean => {
  return UserRights.rights(user.role)[resourceId] === true;
};

const userRolesPMU: UserRolePmu[] = [
  'ROLE_SUPERVISOR',
  'ROLE_ADMIN_INFOCENTRE',
  'ROLE_CONSULTATION',
];

const userRolesPartenaires: UserRolePartenaires[] = [
  'ROLE_OPERATOR',
  'ROLE_RACING_MANAGER',
  'ROLE_DEVELOPER',
];

const userRoles = {
  PMU: userRolesPMU,
  Partenaires: userRolesPartenaires,
};

export const allUserRoles = [...userRoles.PMU, ...userRoles.Partenaires];
