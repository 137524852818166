import type { ApiRace } from '../../types/apiRace';

const isValidOfficialResultTime = (resultTime: string) => {
  // Should match hh:mm:ss exactly (e.g 17:32:23)
  return /^(?:2[0-3]|[01]?\d):[0-5]\d:[0-5]\d$/.test(resultTime);
};

// Race['officialResultTime'] is formatted as follow: 09-11-2022 17:32:23, so we want retrieve only the second part (real official result time only)
const adaptOfficialResultTime = (
  officialResultTime: ApiRace['officialResultTime']
): string | null => {
  if (!officialResultTime) {
    return null;
  }

  const [, resultTime] = officialResultTime.split(' ');

  if (!resultTime) {
    return null;
  }

  return isValidOfficialResultTime(resultTime) ? resultTime : null;
};

export { adaptOfficialResultTime };
