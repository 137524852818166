import isNil from 'lodash/isNil';

import type { Race, RaceStatus } from '@api/types';

// eslint-disable-next-line no-shadow
export enum RaceGait {
  GALLOP,
  TROT,
}

interface Handicap {
  level: number;
  detail: string | null;
}
export interface AdaptedRaceInformations {
  distance: Race['distance'];
  turn: Race['turn'];
  trackType: Race['trackType'];
  course: Race['course'];
  startingMode: Race['startingMode'];
  raceConditions: NonNullable<Race['raceConditions']>['fullConditions'];
  breedConditions: NonNullable<Race['raceConditions']>['breedConditions'];
  genderConditions: NonNullable<Race['raceConditions']>['genderConditions'];
  ageConditions: NonNullable<Race['raceConditions']>['ageConditions'];
  riderConditions: NonNullable<Race['raceConditions']>['riderConditions'];
  handicaps: Handicap[] | null;
  totalPool: NonNullable<Race['earnings']>['totalPool'];
  detailedEarnings: NonNullable<Race['earnings']>['detailedEarnings'];
  trackCondition: Race['trackCondition'];
  gait: RaceGait | null;
  raceNumber: Race['raceNumber'];
  raceStatus: RaceStatus['code'] | null;
  raceType: Race['raceType'];
  discipline: Race['discipline'];
}

export const adaptGait = (discipline: Race['discipline']): RaceGait | null => {
  if (discipline == null) {
    return null;
  }
  switch (discipline) {
    case 'c':
    case 'h':
    case 'p':
    case 's':
      return RaceGait.GALLOP;
    default:
      return RaceGait.TROT;
  }
};

const getDetail = (reference: number | null): string | null => {
  if (isNil(reference)) {
    return null;
  }
  if (reference === 0) {
    return '0';
  }

  const sign = reference > 0 ? '+' : '-';

  return `${sign} ${Math.abs(reference)}`;
};

const getHandicaps = (
  discipline: Race['discipline'],
  handicapReferences: Race['handicapReferences']
): AdaptedRaceInformations['handicaps'] | null => {
  const gait = adaptGait(discipline);
  if (gait !== RaceGait.GALLOP || !handicapReferences) {
    return null;
  }

  return handicapReferences.map((handicapReference) => ({
    level: handicapReference.order,
    detail: getDetail(handicapReference.reference),
  }));
};

const adaptRaceInformations = (race: Race): AdaptedRaceInformations => {
  const {
    distance,
    startingMode,
    turn,
    trackType,
    course,
    discipline,
    handicapReferences,
    trackCondition,
    earnings,
    raceConditions,
    raceNumber,
    raceStatus,
    raceType,
  } = race;

  return {
    distance,
    turn,
    trackType,
    course,
    startingMode,
    raceConditions: raceConditions?.fullConditions ?? null,
    breedConditions: raceConditions?.breedConditions ?? null,
    genderConditions: raceConditions?.genderConditions ?? null,
    ageConditions: raceConditions?.ageConditions ?? null,
    riderConditions: raceConditions?.riderConditions ?? null,
    handicaps: getHandicaps(discipline, handicapReferences),
    totalPool: earnings?.totalPool ?? null,
    detailedEarnings: earnings?.detailedEarnings ?? null,
    trackCondition,
    gait: adaptGait(discipline),
    raceNumber,
    raceStatus,
    raceType,
    discipline,
  };
};

export { adaptRaceInformations };
