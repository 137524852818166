import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import { useUserQuery } from '@api/hooks/useUserQuery';
import { IntlWrapper } from '@components/IntlWrapper';
import { SplashScreen } from '@components/SplashScreen';

import { App } from './App';
import { setupStore } from './store';
import { reportWebVitals } from './utils/web-vitals';

import './index.scss';
import type { SetupWorkerApi } from 'msw';

const prepare = async (): Promise<ReturnType<SetupWorkerApi['start']> | void> => {
  if (window.config.MSW_ENABLED) {
    // Explicitly delete 'MSW_COOKIE_STORE' key from localStorage to avoid cookies to persist between sessions/logins through MSW Server
    window.localStorage.removeItem('MSW_COOKIE_STORE');

    const { worker } = await import('./mocks/browser');

    return worker.start();
  }

  return Promise.resolve();
};

const AppGuard = () => {
  const { status } = useUserQuery();

  if (status.isFetching) {
    return <SplashScreen />;
  }

  // If all required resources are ready/fetched, we render the whole app
  return <App />;
};

prepare().then(() => {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <IntlWrapper>
        <BrowserRouter>
          <Provider store={setupStore()}>
            <AppGuard />
          </Provider>
        </BrowserRouter>
      </IntlWrapper>
    </React.StrictMode>
  );

  // eslint-disable-next-line no-console
  reportWebVitals(console.log);
});
