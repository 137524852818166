import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryCacheLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import microdiff from 'microdiff';

import { adaptMeeting } from '@api/adapters/meetings';
import { socket } from '@api/socket';
import { Meeting } from '@api/types';
import { updateMeetingEvent } from '@store/slices/events';

import { EventType, MeetingEvent } from '../types/Event';

import type { Lang } from '@assets/locales';
import type { messageCallbackType } from '@stomp/stompjs';

type MeetingsArgs = { startDate: string; endDate: string; language: Lang };

export type OnMeetingCacheEntryAdded = QueryCacheLifecycleApi<
  MeetingsArgs,
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
  Meeting[],
  'meetingsApi'
>;

const isMeeting = (wsEvent: { type: EventType }): wsEvent is MeetingEvent => {
  return wsEvent.type === 'MEETING';
};

export const onMeetingCacheEntryAdded = async (
  arg: MeetingsArgs,
  {
    updateCachedData,
    cacheDataLoaded,
    cacheEntryRemoved,
    dispatch,
    getCacheEntry,
  }: OnMeetingCacheEntryAdded
) => {
  let unsubscribe;

  try {
    // wait for the initial query to resolve before proceeding
    await cacheDataLoaded;

    // when data is received from the socket connection to the server,
    // if it is a message and for the appropriate channel,
    // update our query result with the received message

    const callback: messageCallbackType = (message) => {
      const actualCacheData = getCacheEntry().data || [];
      const data = JSON.parse(message.body);

      // we only want to update the cache if the message is a meeting event
      const isMeetingEvent = isMeeting(data);

      if (typeof data.text !== 'string') {
        return;
      }

      if (!isMeetingEvent) {
        return;
      }

      const { id: eventMeetingId, date: eventMeetingDate } = JSON.parse(data.text);

      const iAmConcerned = arg.startDate === eventMeetingDate;

      if (!iAmConcerned) {
        return;
      }

      // we check if the meeting is already in the cache
      const matchedStoredMeetingIndex = actualCacheData.findIndex(
        (storedMeeting) => storedMeeting.id === eventMeetingId
      );

      const adaptedEventMeeting = adaptMeeting(JSON.parse(data.text));

      // we add the new meeting to the cache if it is not already in it
      // otherwise we update the meeting in the cache
      const newCacheData =
        matchedStoredMeetingIndex === -1
          ? [...actualCacheData, adaptedEventMeeting]
          : actualCacheData.map((storedMeeting, index) =>
              index === matchedStoredMeetingIndex ? adaptedEventMeeting : storedMeeting
            );

      // we get the diff between the new meeting and the old one
      // if the meeting was not in the cache, the diff will be the whole meeting
      const diff = microdiff(actualCacheData[matchedStoredMeetingIndex] || {}, adaptedEventMeeting);

      // we update the cache with the computed data
      updateCachedData(() => newCacheData);

      // we dispatch an action to update the updateMeetingEvent slice with the diff
      dispatch(
        updateMeetingEvent({
          id: eventMeetingId,
          diff,
        })
      );
    };

    unsubscribe = socket.subscribe(callback);
  } catch {
    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }
  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;
  // perform cleanup steps once the `cacheEntryRemoved` promise resolves
  // Run cleanup for previous listener here

  unsubscribe?.();
};
