import { createSearchParams } from 'react-router-dom';

import { ROUTES } from './routes';

import type { FormattedDate } from '@utils/date';

export const {
  API_GW_URL: BASE_URL,
  S3_ASSETS_URL,
  B2B_PMU_URL: AUTHENTICATION_PMU,
  B2B_COGNITO_URL: AUTHENTICATION_COGNITO,
  API_CATALOG_LINK_URL,
  CGU_LINK_URL,
  PRIVACY_POLICY_LINK_URL,
  FEEDBACK_URL,
  OLD_INFOCENTRE_URL,
} = window.config;

const hdePrefix = '/hde/v1';
const b2bPrefix = '/b2b-manager';

export const SCHEDULE_MEETINGS = `${hdePrefix}/schedule/meetings`;
export const SCHEDULE_RACES = `${hdePrefix}/schedule/races`;
export const EVENTS = `${hdePrefix}/events`;
export const SCHEDULE_RUNNERS = 'runners';
export const ODDS_FINAL = `${hdePrefix}/final-dividends`;
export const ODDS_BREAKDOWN = `${hdePrefix}/odds/breakdown`;
export const CONTACTS_USER = `${b2bPrefix}/contacts/user`;
export const CONTACTS_LOGOUT = `${b2bPrefix}/contacts/logout`;
export const CONTACTS_VALIDATE_CGU = `${b2bPrefix}/contacts/validate-cgu`;
export const REFRESH_TOKEN = `${b2bPrefix}/contacts/refresh-token`;
export const PARTNERS = `${b2bPrefix}/partners`;
export const PARTNERS_ISSUE_CERTIFICATE = `${b2bPrefix}/partners/issue-certificate`;
export const PARTNERS_DOWNLOAD_CERTIFICATE = `${b2bPrefix}/partners/download-certificate`;

const getRaceUrl = ({
  startDate,
  internalMeetingNumber,
  raceNumber,
}: {
  startDate: FormattedDate;
  internalMeetingNumber: string | number;
  raceNumber: number;
}) => {
  return `${ROUTES.RACECARDS}/${startDate}/m${internalMeetingNumber}/r${raceNumber}`;
};

const getMeetingUrl = ({
  startDate,
  internalMeetingNumber = 1,
}: {
  startDate: FormattedDate;
  internalMeetingNumber?: string | number;
}) => `${ROUTES.RACECARDS}/${startDate}/m${internalMeetingNumber}`;

const getRacescardsUrl = (searchParams?: { startDate?: FormattedDate }) => {
  return searchParams?.startDate
    ? `${ROUTES.RACECARDS}?${createSearchParams(searchParams)}`
    : `${ROUTES.RACECARDS}`;
};
const getQuintePlusUrl = (searchParams?: { startDate?: FormattedDate }) => {
  return searchParams?.startDate
    ? `${ROUTES.QUINTE}?${createSearchParams(searchParams)}`
    : ROUTES.QUINTE;
};

const getNonRunnersUrl = (searchParams?: { startDate?: FormattedDate }) => {
  return searchParams?.startDate
    ? `${ROUTES.NON_RUNNERS}?${createSearchParams(searchParams)}`
    : ROUTES.NON_RUNNERS;
};

const getRacingEventsUrl = (searchParams?: { startDate?: FormattedDate }) => {
  return searchParams?.startDate
    ? `${ROUTES.RACING_EVENTS}?${createSearchParams(searchParams)}`
    : ROUTES.RACING_EVENTS;
};

export {
  getMeetingUrl,
  getRacescardsUrl,
  getRaceUrl,
  getQuintePlusUrl,
  getNonRunnersUrl,
  getRacingEventsUrl,
};
