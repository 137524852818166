import React from 'react';
import { Navigate } from 'react-router-dom';

import { ProtectedResourceId } from '@api/types/user/userAccessRights';
import { ROUTES } from '@config/routes';

import { useGrantAccess } from '../logic/useGrantAccess';

type GrantAccessOrRedirectProps = {
  children: JSX.Element;
  resourceId: ProtectedResourceId;
};

export const GrantAccessOrRedirect = ({ children, resourceId }: GrantAccessOrRedirectProps) => {
  const canDisplay = useGrantAccess({ resourceId });

  if (!canDisplay) {
    return <Navigate to={ROUTES.NOT_FOUND} replace />;
  }

  return children;
};
