import { TornadoTypes } from '@pmu/tornado';

import { Horse } from '../../types';

import { adaptDam } from './dam';
import { adaptGender } from './gender';
import { adaptSire } from './sire';

import type { ApiRunner } from '../../types/apiRunner';

const adaptHorse = (horse: ApiRunner['horse']): Horse | null => {
  if (!horse) {
    return null;
  }

  const { gender, origin, dam, sire, ...otherProps } = horse;

  return {
    ...otherProps,
    dam: adaptDam(dam),
    sire: adaptSire(sire),
    gender: adaptGender(gender),
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    origin: (origin?.code as TornadoTypes.IsoCode) ?? null,
  };
};

export { adaptHorse };
