import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  PreloadedState,
  createListenerMiddleware,
} from '@reduxjs/toolkit';

import {
  meetingsApi,
  racesApi,
  userApi,
  partnersApi,
  raceApi,
  eventsApi,
  oddsApi,
  runnersApi,
} from '@api/services';

import { authReducer } from './slices/authSlice';
import { meetingEventsReducer, raceEventsReducer } from './slices/events';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  // Add the generated reducer as a specific top-level slices
  [meetingsApi.reducerPath]: meetingsApi.reducer,
  [racesApi.reducerPath]: racesApi.reducer,
  [raceApi.reducerPath]: raceApi.reducer,
  [runnersApi.reducerPath]: runnersApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [partnersApi.reducerPath]: partnersApi.reducer,
  [oddsApi.reducerPath]: oddsApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  auth: authReducer,
  meetingEvents: meetingEventsReducer,
  raceEvents: raceEventsReducer,
});

const listenerMiddleware = createListenerMiddleware();

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(
          meetingsApi.middleware,
          racesApi.middleware,
          raceApi.middleware,
          runnersApi.middleware,
          userApi.middleware,
          partnersApi.middleware,
          eventsApi.middleware,
          oddsApi.middleware
        )
        .prepend(listenerMiddleware.middleware),
  });
};
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
