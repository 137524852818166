import React from 'react';
import { Toggle } from '@pmu/tornado';

import { CookiesConfigItem } from '@components/CookiesModal/config';

interface ConfigTabItemProps {
  cookieName: string;
  config: CookiesConfigItem;
  onChange: (cookieName: string, toggle: boolean) => void;
  toggled: boolean;
}

const ConfigTabItem = ({ cookieName, config, onChange, toggled }: ConfigTabItemProps) => {
  const handleChange = () => {
    onChange(cookieName, !toggled);
  };

  return (
    <div className="CookiesDialog-config-item">
      <div>
        <p className="CookiesDialog-config-title">{config.title}</p>
        <p className="CookiesDialog-config-description">{config.description}</p>
      </div>
      <Toggle toggled={toggled} onToggle={handleChange} />
    </div>
  );
};

export { ConfigTabItem };
