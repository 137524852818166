import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Routes } from 'react-router-dom';

import { CookiesDialog } from '@components/CookiesModal';
import { createCookiesConfig } from '@components/CookiesModal/config';
import { GrantAccessOrRedirect } from '@components/GrantAccess/GrantAccessOrRedirect';
import { AccountLayout } from '@components/Layout/Account';
import { AppLayout } from '@components/Layout/App';
import { CommonLayout } from '@components/Layout/Common';
import { LoginLayout } from '@components/Layout/Login';
import { Private } from '@components/Private';
import { ROUTES } from '@config/routes';
import { useCookies } from '@hooks/useCookies';
import { useGTM } from '@hooks/useGTM';
import { useWebSocket } from '@hooks/useWebSocket';

import {
  Account,
  CguValidation,
  Home,
  Login,
  Meeting,
  NotFound,
  QuintePlus,
  Race,
  NonRunners,
  RacingEvents,
  Racecards,
} from './features';
import './style/layout/print.scss';

const App = () => {
  const intl = useIntl();
  const cookiesConfig = createCookiesConfig(intl);
  useWebSocket();
  useGTM();
  useCookies(cookiesConfig);

  return (
    <>
      <CookiesDialog cookiesConfig={cookiesConfig} />
      <Routes>
        <Route path={ROUTES.HOME} element={<CommonLayout />}>
          <Route
            index
            element={
              <Private>
                <GrantAccessOrRedirect resourceId="app.route.home">
                  <Home />
                </GrantAccessOrRedirect>
              </Private>
            }
          />
          <Route path={ROUTES.LOGIN} element={<LoginLayout />}>
            <Route index element={<Login />} />
          </Route>
          <Route path={ROUTES.CGU_VALIDATION} element={<LoginLayout />}>
            <Route
              index
              element={
                <Private>
                  <CguValidation />
                </Private>
              }
            />
          </Route>
          <Route path={ROUTES.RACECARDS} element={<AppLayout />}>
            <Route
              index
              element={
                <Private>
                  <GrantAccessOrRedirect resourceId="app.route.racecards">
                    <Racecards />
                  </GrantAccessOrRedirect>
                </Private>
              }
            />
            <Route
              path={ROUTES.MEETINGS}
              element={
                <Private>
                  <GrantAccessOrRedirect resourceId="app.route.meetings">
                    <Meeting />
                  </GrantAccessOrRedirect>
                </Private>
              }
            />
            <Route
              path={ROUTES.RACES}
              element={
                <Private>
                  <GrantAccessOrRedirect resourceId="app.route.races">
                    <Race />
                  </GrantAccessOrRedirect>
                </Private>
              }
            />
          </Route>
          <Route path={ROUTES.QUINTE} element={<AppLayout />}>
            <Route
              index
              element={
                <Private>
                  <GrantAccessOrRedirect resourceId="app.route.quinte">
                    <QuintePlus />
                  </GrantAccessOrRedirect>
                </Private>
              }
            />
          </Route>
          <Route path={ROUTES.NON_RUNNERS} element={<AppLayout />}>
            <Route
              index
              element={
                <Private>
                  <GrantAccessOrRedirect resourceId="app.route.nonRunners">
                    <NonRunners />
                  </GrantAccessOrRedirect>
                </Private>
              }
            />
          </Route>
          <Route path={ROUTES.RACING_EVENTS} element={<AppLayout />}>
            <Route
              index
              element={
                <Private>
                  <GrantAccessOrRedirect resourceId="app.route.racingEvents">
                    <RacingEvents />
                  </GrantAccessOrRedirect>
                </Private>
              }
            />
          </Route>
          <Route path={ROUTES.ACCOUNT} element={<AccountLayout />}>
            <Route
              index
              element={
                <Private>
                  <GrantAccessOrRedirect resourceId="app.route.account">
                    <Account />
                  </GrantAccessOrRedirect>
                </Private>
              }
            />
          </Route>

          <Route
            path="*"
            element={
              <Private>
                <NotFound />
              </Private>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export { App };
