import React, { useState } from 'react';
import { Button, Heading } from 'react-aria-components';
import classNames from 'classnames';
import { Toggle, TranslatedText } from '@pmu/tornado';

import { CookiesModalTab } from '@components/CookiesModal';
import { CookiesConfig, CookiesToggle } from '@components/CookiesModal/config';
import { ConfigTabItem } from '@components/CookiesModal/ConfigTabItem';
import { createCookieState } from '@utils/cookie';

interface ConfigTabProps {
  onChangeTab: (tab: CookiesModalTab) => void;
  onConfirm: (data: CookiesToggle) => void;
  config: CookiesConfig;
}

const ConfigTab = ({ onChangeTab, onConfirm, config }: ConfigTabProps) => {
  const [allToggled, setAllToggled] = useState(true);
  const [cookiesToggles, setCookiesToggles] = useState<CookiesToggle>(
    createCookieState(config, true)
  );
  const handleChangeTabInitial = () => {
    onChangeTab(CookiesModalTab.INITIAL);
  };

  const handleChange = (cookieName: string, toggle: boolean) => {
    setCookiesToggles((prevState) => {
      const newState = { ...prevState, [cookieName]: toggle };
      const newAllToggled = Object.keys(newState).every((name) => newState[name]);
      setAllToggled(newAllToggled);

      return newState;
    });
  };

  const handleChangeAll = () => {
    if (allToggled) {
      setAllToggled(false);
      setCookiesToggles(createCookieState(config, false));
    } else {
      setAllToggled(true);
      setCookiesToggles(createCookieState(config, true));
    }
  };

  const handleConfirm = () => {
    onConfirm(cookiesToggles);
  };

  const handleAllConfirm = () => {
    onConfirm(createCookieState(config, true));
  };

  const cookiesKeys = Object.keys(config);

  const classContentElements = classNames('CookiesDialog-content', {
    'CookiesDialog-content-one-element': cookiesKeys.length === 1,
  });

  return (
    <div className="CookiesDialog-container CookiesDialog-container-config">
      <div className="CookiesDialog-container-title">
        <Heading slot="title">
          <TranslatedText id="app.cookies.policy.config.title" />
        </Heading>
      </div>
      <div className={classContentElements}>
        <p>
          <TranslatedText id="app.cookies.policy.config.body" />
        </p>
        <div className="CookiesDialog-content-config">
          {cookiesKeys.length > 1 ? (
            <div className="CookiesDialog-content-config-all-toggle">
              <p>
                <TranslatedText
                  id={
                    allToggled ? 'app.cookies.config.all.disable' : 'app.cookies.config.all.enable'
                  }
                />
              </p>
              <Toggle toggled={allToggled} onToggle={handleChangeAll} />
            </div>
          ) : null}
          <div className="CookiesDialog-content-config-container">
            {cookiesKeys.map((key) => (
              <ConfigTabItem
                key={`cookie-item-${key}`}
                cookieName={key}
                config={config[key]}
                onChange={handleChange}
                toggled={cookiesToggles[key]}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="CookiesDialog-action">
        <Button
          className="CookiesDialog-action-item CookiesDialog-action-params"
          onPress={handleChangeTabInitial}
        >
          <TranslatedText id="app.generic.return" />
        </Button>
        <Button
          className="CookiesDialog-action-item CookiesDialog-action-accept"
          onPress={handleAllConfirm}
        >
          <TranslatedText id="app.generic.accept.all" />
        </Button>
        <Button
          className="CookiesDialog-action-item CookiesDialog-action-params"
          onPress={handleConfirm}
        >
          <TranslatedText id="app.generic.finish" />
        </Button>
      </div>
    </div>
  );
};

export { ConfigTab };
