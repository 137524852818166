import { TornadoTypes } from '@pmu/tornado';

import { Sire } from '@api/types';

import { adaptGender } from './gender';

import type { ApiRunner } from '../../types/apiRunner';

const adaptSire = (sire: NonNullable<ApiRunner['horse']>['sire']): Sire | null => {
  if (!sire) {
    return null;
  }

  const { gender, origin, ...otherProps } = sire;

  return {
    ...otherProps,
    gender: adaptGender(gender),
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    origin: (origin?.code as TornadoTypes.IsoCode) ?? null,
  };
};

export { adaptSire };
