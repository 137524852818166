import React from 'react';
import { Link } from 'react-router-dom';
import { TimeZone } from '@pmu/tornado';

import {
  getUser,
  isUserAuthenticatedWithAcceptedCGUs as isUserAuthenticatedWithAcceptedCGUsSelector,
} from '@api/services';
import { User } from '@api/types';
import { Lang } from '@assets/locales';
import { ProfileMenu } from '@components/Layout/components/ProfileMenu';
import { ROUTES } from '@config/routes';
import { useLocale } from '@hooks/useLocale';
import { useAppSelector } from '@store/hooks';
import { isUserRoleDeveloper } from '@utils/isUserDeveloper';
import './index.scss';

const Header = () => {
  const isUserAuthenticatedWithAcceptedCGUs = useAppSelector(
    isUserAuthenticatedWithAcceptedCGUsSelector
  );
  const user = useAppSelector<User>(getUser as () => User);

  const { locale, handleChangeLang } = useLocale();

  if (!isUserAuthenticatedWithAcceptedCGUs) {
    return null;
  }

  const onLocalChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    handleChangeLang(e.target.value as Lang);

  const redirectUrl = isUserRoleDeveloper(user) ? ROUTES.ACCOUNT : ROUTES.HOME;
  const isLangSelectorEnable = window.config.LANG_SELECTOR_ENABLE;

  return (
    <header className="layout layout-header">
      <Link to={redirectUrl}>
        <img src="/logo-PMU-infocentre.svg" alt="PMU Infocentre" width="166" />
      </Link>
      <TimeZone />

      {/* ici juste pour tester les langues */}
      {isLangSelectorEnable ? (
        <select
          data-testid="infocentre/layout/header/select"
          value={locale}
          onChange={onLocalChange}
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
          <option value="zh">Zh</option>
        </select>
      ) : null}

      <ProfileMenu />
    </header>
  );
};

export { Header };
