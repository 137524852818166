import { TornadoTypes } from '@pmu/tornado';

import { Dam } from '@api/types';

import { adaptGender } from './gender';
import { adaptSire } from './sire';

import type { ApiRunner } from '../../types/apiRunner';

const adaptDam = (dam: NonNullable<ApiRunner['horse']>['dam']): Dam | null => {
  if (!dam) {
    return null;
  }

  const { gender, origin, sire, ...otherProps } = dam;

  return {
    ...otherProps,
    gender: adaptGender(gender),
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    origin: (origin?.code as TornadoTypes.IsoCode) ?? null,
    sire: adaptSire(sire),
  };
};

export { adaptDam };
