import { IntlShape } from 'react-intl';

// eslint-disable-next-line no-shadow
export enum CookiesBlockType {
  SCRIPT_TAG = 'script-tag',
  WRAPPER = 'wrapper',
}

export interface CookiesConfigItem {
  title: string; // Title cookie in cookie config modal
  description?: string; // Description cookie in cookie config modal
  type: CookiesBlockType; // Type for blocking script
  cookieRegex?: RegExp; // Regexp for delete cookie if disable
}

export interface CookiesConfig {
  [key: string]: CookiesConfigItem;
}

export const cookiesName = {
  analytics: 'analytics',
};

export const scriptCookieId = 'data-cookie-id';
export const localStorageCookieConsent = 'cookie-consent';

export type CookiesToggle = { [key: string]: boolean };

export const createCookiesConfig = (intl: IntlShape): CookiesConfig => {
  return {
    [cookiesName.analytics]: {
      title: intl.formatMessage({ id: 'app.cookies.policy.analytics' }),
      type: CookiesBlockType.SCRIPT_TAG,
      cookieRegex: /(_ga|gid)/,
    },
  };
};
