import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryCacheLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { adaptRunners } from '@api/adapters/runners';
import { socket } from '@api/socket';
import { Runner } from '@api/types';

import { RunnersArgs } from '../../runners';
import { isRace } from '../races';

import type { messageCallbackType } from '@stomp/stompjs';

export type OnRunnersCacheEntryAdded = QueryCacheLifecycleApi<
  RunnersArgs,
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
  Runner[],
  'runnersApi'
>;

export const onRunnersCacheEntryAdded = async (
  arg: RunnersArgs,
  { updateCachedData, cacheDataLoaded, cacheEntryRemoved }: OnRunnersCacheEntryAdded
) => {
  let unsubscribe;
  try {
    // wait for the initial query to resolve before proceeding
    await cacheDataLoaded;
    // when data is received from the socket connection to the server,
    // if it is a message and for the appropriate channel,
    // update our query result with the received message
    const callback: messageCallbackType = (message) => {
      const data = JSON.parse(message.body);
      // we only want to update the cache if the message is a race event
      const isRaceEvent = isRace(data);
      if (!isRaceEvent) {
        return;
      }

      if (typeof data.text !== 'string') {
        return;
      }

      const { date: eventRaceDate, racecourse, runners, raceNumber } = JSON.parse(data.text);
      const racecourseCode = racecourse?.code;
      const iAmConcerned =
        arg.date === eventRaceDate &&
        arg.racecourseCode === racecourseCode &&
        arg.raceNumber === raceNumber;
      if (!iAmConcerned) {
        return;
      }
      const adaptedRunners = adaptRunners(runners);
      // we update the cache with the computed data
      updateCachedData(() => adaptedRunners);
    };
    unsubscribe = socket.subscribe(callback);
  } catch {
    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }
  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;
  // perform cleanup steps once the `cacheEntryRemoved` promise resolves
  // Run cleanup for previous listener here
  unsubscribe?.();
};
