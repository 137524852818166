import { ProtectedResourceId } from '@api/types/user/userAccessRights';

import { useGrantAccess } from '../logic/useGrantAccess';

type GrantAccessProps = {
  children: JSX.Element;
  resourceId: ProtectedResourceId;
};

export const GrantAccess = ({ children, resourceId }: GrantAccessProps) => {
  const canDisplay = useGrantAccess({ resourceId });
  if (!canDisplay) {
    return null;
  }

  return children;
};
