import { GenderCode } from '@api/types';

import type { ApiRunner } from '../../types/apiRunner';

const adaptGender = (gender: NonNullable<ApiRunner['horse']>['gender']): GenderCode | null => {
  switch (gender?.code) {
    case 'F':
    case 'M':
    case 'H':
      return gender.code;
    default:
      return null;
  }
};

export { adaptGender };
