import React, { createContext, PropsWithChildren, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import locales, { type Lang } from '@assets/locales';

const LANGUAGE_LOCALSTORAGE_KEY = 'preferredLanguage';

type IntlWrapperContextState = {
  locale: Lang;
  changeLocale: (locale: Lang) => void;
};

const getUserOrNavigatorLanguage = (): Lang => {
  const savedLanguage = window.localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY);
  if (savedLanguage !== null) {
    return savedLanguage as Lang;
  }
  const userLocale = navigator.languages.length ? navigator.languages[0] : navigator.language;

  return userLocale.includes('en') ? 'en' : 'fr';
};

const defaultContextValue: IntlWrapperContextState = {
  locale: getUserOrNavigatorLanguage(),
  changeLocale: () => null,
};

const Context = createContext<IntlWrapperContextState>(defaultContextValue);

const IntlWrapper = ({
  children,
  locale: initialLocale = defaultContextValue.locale,
}: PropsWithChildren<{ locale?: Lang }>): JSX.Element => {
  const [locale, setLocale] = useState<Lang>(initialLocale);
  const [messages, setMessages] = useState(locales[initialLocale]);

  const changeLocale = (lang: Lang): void => {
    window.localStorage.setItem(LANGUAGE_LOCALSTORAGE_KEY, lang);
    setLocale(lang);

    const expectedMessages = locales[lang];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    setMessages(expectedMessages ?? locales.en);
  };

  const value = useMemo(() => ({ locale, changeLocale }), [locale]);

  return (
    <Context.Provider value={value}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlWrapper, Context };
