export type GTMData = {
  event?: string;
  page_name?: string;
  user_type?: string;
  bet_reunion_id?: string;
  bet_course_id?: string;
  etat_course?: string | null;
  discipline?: string;
};

type PlatformSpecificMethods = {
  pushTagGTM: (data: GTMData) => void;
};

const platformSpecificMethods: PlatformSpecificMethods = {
  pushTagGTM: () => {
    // nothing
  },
};

const initialize = ({ pushTagGTM }: PlatformSpecificMethods) => {
  platformSpecificMethods.pushTagGTM = pushTagGTM;
};

const queue: GTMData[] = [];

const runtime = {
  ready: false,
  userData: {},
};

// The \s meta character in JavaScript regular expressions matches any whitespace character: spaces, tabs, newlines and Unicode spaces.
// The g flag in regex tells JavaScript to replace it multiple times.
const formatEvent = (event: string) =>
  event.toLowerCase().replace(/&/g, '_and_').replace(/\s/g, '_').replace(/__/g, '_');

const sendTag = (event: string, data: GTMData = {}) => {
  const formattedEvent = formatEvent(event);

  const preparedData = {
    event: 'pmu_event',
    page_name: formattedEvent,
    ...data,
  };

  if (runtime.ready) {
    platformSpecificMethods.pushTagGTM({
      ...preparedData,
      ...runtime.userData,
    });
  } else {
    queue.push(preparedData);
  }
};

export { formatEvent, initialize, platformSpecificMethods, queue, runtime, sendTag };
