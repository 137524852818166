import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query';

import { BASE_URL, REFRESH_TOKEN } from '@config/urls';
import { setAuth } from '@store/slices/authSlice';

import { socket } from '../socket';

import type { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import type { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query';

const fetchOptions: Partial<FetchBaseQueryArgs> = {
  credentials: 'include',
};

const query = fetchBaseQuery({
  baseUrl: BASE_URL,
  ...fetchOptions,
});

const getAccessToken = async (api: BaseQueryApi) => {
  try {
    const response = await fetch(`${BASE_URL}${REFRESH_TOKEN}`, fetchOptions);
    if (response.ok) {
      api.dispatch(setAuth(true));

      return response;
    }

    throw new Error('[baseQuery]: Network error getting access_token');
  } catch (err) {
    api.dispatch(setAuth(false));
    socket.close();

    throw err;
  }
};

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const originalQuery = () => query(args, api, extraOptions);
  const result = await originalQuery();

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    try {
      await getAccessToken(api);

      return await originalQuery();
    } catch (error) {
      return result;
    }
  }

  // We set isAuth to true
  // This prevent user from been stuck in login page when refreh_token is expired and user try to login
  api.dispatch(setAuth(true));

  return result;
};

export { baseQuery };
