import React from 'react';
import { Button, Heading } from 'react-aria-components';
import { TranslatedText } from '@pmu/tornado';

import { CookiesModalTab } from '@components/CookiesModal';
import { CookiesConfig, CookiesToggle } from '@components/CookiesModal/config';
import { S3_ASSETS_URL } from '@config/urls';
import { createCookieState } from '@utils/cookie';

const cookiesNoticeURL = `${S3_ASSETS_URL}/pdf/Cookie_management_policy_EN_FR.pdf`;

interface InitialTabProps {
  onChangeTab: (tab: CookiesModalTab) => void;
  onConfirm: (data: CookiesToggle) => void;
  config: CookiesConfig;
}

const InitialTab = ({ onChangeTab, onConfirm, config }: InitialTabProps) => {
  const handleChangeTab = () => {
    onChangeTab(CookiesModalTab.CONFIG);
  };

  const handleAllConfirm = () => {
    onConfirm(createCookieState(config, true));
  };

  const handleAllRefuse = () => {
    onConfirm(createCookieState(config, false));
  };

  return (
    <div className="CookiesDialog-container">
      <div className="CookiesDialog-container-not-accept">
        <Button onPress={handleAllRefuse}>
          <TranslatedText id="app.cookies.policy.initial.continue.without.accept" />
        </Button>
      </div>
      <div className="CookiesDialog-container-title">
        <Heading slot="title">
          <TranslatedText id="app.cookies.policy.initial.title" />
        </Heading>
      </div>
      <div className="CookiesDialog-content">
        <p>
          <TranslatedText id="app.cookies.policy.initial.body" />
        </p>
        <p>
          <TranslatedText id="app.cookies.policy.initial.body.secondary" />
        </p>
        <p className="CookiesDialog-cookie-policy">
          <a target="_blank" rel="noopener noreferrer" href={cookiesNoticeURL}>
            <TranslatedText id="app.cookies.policy.initial.body.cookie.policy" />
          </a>
        </p>
      </div>
      <div className="CookiesDialog-action">
        <Button
          className="CookiesDialog-action-item CookiesDialog-action-params"
          onPress={handleChangeTab}
        >
          <TranslatedText id="app.generic.configure" />
        </Button>
        <Button
          className="CookiesDialog-action-item CookiesDialog-action-accept"
          onPress={handleAllConfirm}
        >
          <TranslatedText id="app.generic.accept" />
        </Button>
      </div>
    </div>
  );
};

export { InitialTab };
