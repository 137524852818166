import { adaptRequestResponse } from '@api/adapters/requestResponse';
import { useGetUserQuery } from '@api/services';

import type { RequestResponse } from '@api/services/types/RequestStatus';
import type { User } from '@api/types';

const useUserQuery = (): RequestResponse<User> => {
  const { data, error, isSuccess, isFetching, isError, isUninitialized } = useGetUserQuery();

  return adaptRequestResponse({
    data,
    error,
    isSuccess,
    isFetching,
    isUninitialized,
    isError,
  });
};

export { useUserQuery };
