import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
// eslint-disable-next-line no-shadow
export enum ErrorCode {
  ERROR400,
  ERROR500,
}

// eslint-disable-next-line no-shadow
export enum DataStatus {
  IDLE,
  FETCHING,
}

export interface ErrorStatus {
  errorCode: ErrorCode;
}

const serverErrorResponse = [500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511];
const clientErrorResponse = [
  400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 418,
  421, 422, 423, 424, 425, 426, 428, 429, 431, 451,
];

export interface RequestResponseStatus {
  isSuccess: boolean;
  isFetching: boolean;
  isError: boolean;
  isServerError: boolean;
  isClientError: boolean;
  isUninitialized: boolean;
  isSuccessWithData: boolean;
  isSuccessWithNoData: boolean;
}

export interface FetchingStatus {
  dataStatus: DataStatus;
}

export interface SuccessStatus<T> {
  value: T;
}

export type RequestStatus<T = unknown> = ErrorStatus | FetchingStatus | SuccessStatus<T>;

export function isFetching(value: RequestStatus): value is FetchingStatus {
  return 'dataStatus' in value;
}

export function isError(value: RequestStatus): value is ErrorStatus {
  return 'errorCode' in value;
}

export function isSuccess<T>(value: RequestStatus<T>): value is SuccessStatus<T> {
  return 'value' in value;
}
export type RequestResponse<T> = {
  data?: T;
  status: RequestResponseStatus;
};

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error !== null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with an error 500 'status'
 */
export function isServerError(error: unknown): error is { message: string } {
  return (
    isFetchBaseQueryError(error) &&
    typeof error.status === 'number' &&
    serverErrorResponse.includes(error.status)
  );
}

/**
 * Type predicate to narrow an unknown error to an object t with an error 400 'status'
 */
export function isClientError(error: unknown): error is { message: string } {
  return (
    isFetchBaseQueryError(error) &&
    typeof error.status === 'number' &&
    clientErrorResponse.includes(error.status)
  );
}
