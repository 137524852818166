import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

const SplashScreen = () => {
  return (
    <div className="SplashScreen-container">
      <img
        src="/logo-PMU-infocentre.svg"
        alt="Infocentre Logo"
        title="Infocentre"
        width="280"
        height="auto"
      />
      <Player src="/splash-screen-horse.json" className="SplashScreen-horse" loop autoplay />
    </div>
  );
};

export { SplashScreen };
