import React from 'react';
import { Outlet } from 'react-router-dom';

import { useLayout } from '../Common';

import './index.scss';

const LoginLayout = () => {
  useLayout('Login');

  return (
    <section className="Login">
      <Outlet />
    </section>
  );
};

export { LoginLayout };
