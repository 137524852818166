import { useEffect, useState } from 'react';

type UseMediaQueryReturn = {
  atMost: boolean;
};

const useMediaQuery = (): UseMediaQueryReturn => {
  const bpMd = 1280 - 1;
  const mq = `(max-width: ${bpMd}px)`;
  const mediaWatcher = window.matchMedia(`screen and ${mq}`);

  const [atMost, setAtMost] = useState(mediaWatcher.matches);

  useEffect(() => {
    const updateIsNarrowScreen = (e: MediaQueryListEvent) => setAtMost(e.matches);

    mediaWatcher.addEventListener('change', updateIsNarrowScreen);

    // clean up after ourselves
    return () => {
      mediaWatcher.removeEventListener('change', updateIsNarrowScreen);
    };
  }, []);

  return {
    atMost,
  };
};

export { useMediaQuery };
