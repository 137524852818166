/* eslint-disable no-console */
import {
  Client,
  type Client as ClientType,
  type IFrame,
  type messageCallbackType,
  type StompSubscription,
} from '@stomp/stompjs';

import type { Partner } from '@api/types';

// TODO Implémentation d'un timeout exponentiel avec éventuellement une limite de max retry

class StompConnection {
  private url: string | null = null;

  private partnerId: Partner['id'] | null = null;

  private client: ClientType | null = null;

  private subscribers = new Map<messageCallbackType, StompSubscription | undefined>();

  private onReconnectHandlers: VoidFunction[] = [];

  constructor(url: string) {
    this.subscribers = new Map();
    this.url = url;
  }

  public connect(partnerId: Partner['id']) {
    this.client = new Client({
      brokerURL: `${this.url}/messages/${partnerId}`,
      debug: (msg) => {
        // Find another way to do it or find a try some fixes for the following error:
        // The 'import.meta' meta-property is only allowed when the '--module' option is 'es2020', 'es2022', 'esnext', 'system', 'node16', or 'nodenext'.
        // @see https://github.com/kulshekhar/ts-jest/issues/3888
        // if (import.meta.env.MODE !== 'development') {
        //   return;
        // }

        console.info(`WS:::debug ${msg}`);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 30000,
      heartbeatOutgoing: 30000,
    });

    this.partnerId = partnerId;

    this.client.onConnect = () => {
      this.client?.debug('Connected to server');

      if (this.subscribers.size > 0) {
        const topicUrl = this.getTopicUrl();

        // We clone the original Map to avoid mutate it map through iterations because it produce an infinite loop
        new Map(this.subscribers).forEach((maybeSubscription, listener) => {
          if (topicUrl) {
            // We try first to unsubscribe the existing subscription
            if (maybeSubscription) {
              try {
                this.client?.unsubscribe(maybeSubscription.id);
              } catch (error) {
                this.client?.debug('OnConnect - Unsubcription failed');
              }
            }

            // Then we re-run a subscription for this listener
            const newSubscription = this.client?.subscribe(topicUrl, listener);
            this.subscribers.set(listener, newSubscription);
          }
        });
      }

      // Call all registered callbacks when succesfully re-connected to WS
      if (this.onReconnectHandlers.length > 0) {
        this.onReconnectHandlers.forEach((callback) => {
          callback();
        });
      }
    };

    this.client.onWebSocketClose = (event: CloseEvent) => {
      this.client?.debug(`OnWebSocketClose - Server connection lost. Code: ${event.code}`);
    };

    this.client.onUnhandledMessage = (message) => {
      this.client?.debug(`OnUnhandledMessage - Server connection lost. Code: ${message.body}`);
    };

    this.client.onUnhandledFrame = (frame) => {
      this.client?.debug(`OnUnhandledFrame - Server connection lost. Code: ${frame.body}`);
    };

    this.client.onWebSocketError = (event: CloseEvent) => {
      this.client?.debug(`OnWebSocketError. Code: ${event.code}`);
    };

    this.client.onUnhandledReceipt = (frame) => {
      this.client?.debug(`OnUnhandledReceipt. Code: ${frame.body}`);
    };

    this.client.onStompError = (frame: IFrame) => {
      this.client?.debug(`OnStompError. Frame: ${frame.body}`);
    };

    this.client.activate();

    console.info('WS:::connect');
  }

  private getTopicUrl() {
    // Retrieve the topic url formatted as follow: /messages/<partnerId>
    return this.partnerId ? `/messages/${this.partnerId}` : null;
  }

  public subscribe(callback: messageCallbackType): () => void {
    let subscription: StompSubscription | undefined;
    const topicUrl = this.getTopicUrl();

    if (this.client && this.isConnected() && topicUrl) {
      subscription = this.client.subscribe(topicUrl, callback);
    }

    this.subscribers.set(callback, subscription);

    return () => {
      subscription?.unsubscribe();
      this.subscribers.delete(callback);
    };
  }

  public close() {
    if (this.isConnected()) {
      if (this.subscribers.size > 0) {
        this.subscribers.forEach((subscription) => {
          if (subscription) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            this.client?.unsubscribe?.(subscription.id);
          }
        });
        this.subscribers.clear();
      }

      this.client?.deactivate();
      this.client = null;
    }
  }

  public onReconnect(callback: VoidFunction) {
    if (!this.onReconnectHandlers.includes(callback)) {
      this.onReconnectHandlers.push(callback);
    }
  }

  public isConnected() {
    return Boolean(this.client?.connected);
  }
}

export const socket = new StompConnection(window.config.WS_URL);
