export interface UserAccessRights {
  'app.layout.profileMenu.item.account': boolean;
  'app.layout.profileMenu.item.subscriptions': boolean;
  'app.fiches.racecards.example': boolean;
  'app.fiches.account.corporatePortal': boolean;
  'app.fiches.account.accessPermissions': boolean;
  'app.route.login': boolean;
  'app.route.account': boolean;
  'app.route.home': boolean;
  'app.route.racecards': boolean;
  'app.route.meetings': boolean;
  'app.route.races': boolean;
  'app.route.quinte': boolean;
  'app.route.nonRunners': boolean;
  'app.route.racingEvents': boolean;
  'app.layout.sidebar': boolean;
}

export type ProtectedResourceId = keyof UserAccessRights;

export function extendRights(
  accessRights: Readonly<UserAccessRights>,
  override: Partial<UserAccessRights>
): UserAccessRights {
  return {
    ...accessRights,
    ...override,
  };
}
