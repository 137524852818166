import React from 'react';
import { useIntl } from 'react-intl';
import {
  Events,
  Home,
  List,
  News,
  NoRunners,
  SidebarMenu as SidebarMenuTornado,
  SidebarMenuItem,
} from '@pmu/tornado';

import { getUser } from '@api/services';
import { User } from '@api/types';
import { ROUTES } from '@config/routes';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { extractUserData } from '@services/helpers';
import { sendTag } from '@services/SendTag';
import { useAppSelector } from '@store/hooks';

import { SidebarMenuItemExternal } from './SidebarMenuItemExternal';
import { SidebarMenuItemFeedback } from './SidebarMenuItemFeedback';

const SidebarMenu = () => {
  const { atMost } = useMediaQuery();
  const translations = useIntl();

  const sidebarMenuItemLabels = {
    home: translations.formatMessage({ id: 'app.categories.home' }),
    meetingsCalendar: translations.formatMessage({ id: 'app.categories.meetingsCalendar' }),
    programme: translations.formatMessage({ id: 'app.categories.programme' }),
    nonRunners: translations.formatMessage({ id: 'app.categories.nonRunners' }),
    racingEvents: translations.formatMessage({ id: 'app.categories.racingEvents' }),
    'quinte+': translations.formatMessage({ id: 'app.categories.quinte' }),
    pronostics: translations.formatMessage({ id: 'app.categories.pronostics' }),
    publications: translations.formatMessage({ id: 'app.categories.publications' }),
  };

  const user = useAppSelector<User>(getUser as () => User);

  const payloadForGTM = extractUserData(user);

  const sendDashboardTag = () => {
    sendTag('infocentre.clic.sidebar.dashboard', payloadForGTM);
  };

  const sendProgramTag = () => {
    sendTag('infocentre.clic.sidebar.programme', payloadForGTM);
  };

  const sendQuinteTag = () => {
    sendTag('infocentre.clic.sidebar.quinte_jour', payloadForGTM);
  };

  const sendNonRunnersTag = () => {
    sendTag('infocentre.clic.sidebar.non_partants', payloadForGTM);
  };

  const sendOnToggleTag = (isOpened: boolean) => {
    const eventName = isOpened
      ? 'infocentre.clic.sidebar.ouvrir'
      : 'infocentre.clic.sidebar.fermer';
    sendTag(eventName, payloadForGTM);
  };

  return (
    <SidebarMenuTornado mini={atMost} onToggle={sendOnToggleTag} data-testid="app/menu">
      <SidebarMenuItem
        to={ROUTES.HOME}
        Icon={Home}
        label={sidebarMenuItemLabels.home}
        onClick={sendDashboardTag}
      />
      <SidebarMenuItemExternal idLabel="meetingsCalendar" />
      <SidebarMenuItem
        to={ROUTES.RACECARDS}
        Icon={List}
        label={sidebarMenuItemLabels.programme}
        onClick={sendProgramTag}
      />
      <SidebarMenuItem
        to={ROUTES.QUINTE}
        Icon={Events}
        label={sidebarMenuItemLabels['quinte+']}
        onClick={sendQuinteTag}
      />
      <SidebarMenuItem
        to={ROUTES.NON_RUNNERS}
        Icon={NoRunners}
        label={sidebarMenuItemLabels.nonRunners}
        onClick={sendNonRunnersTag}
      />
      <SidebarMenuItem
        to={ROUTES.RACING_EVENTS}
        Icon={News}
        label={sidebarMenuItemLabels.racingEvents}
      />
      <SidebarMenuItemExternal idLabel="pronostics" />
      <SidebarMenuItemExternal idLabel="publications" />
      <SidebarMenuItemFeedback />
    </SidebarMenuTornado>
  );
};

export { SidebarMenu };
