import { Race, User } from '@api/types';
import { RaceGait, adaptRaceInformations } from '@components/Race/logic/adapter/raceInformations';
import { adaptRaceStatus } from '@features/Meeting/logic/adapter/raceStatus';

import { GTMData } from './SendTag';

const extractUserData = (user: User): GTMData => ({ user_type: user.role });

const extractCourseData = (race: Race | undefined, internalMeetingNumber: number): GTMData => {
  if (race) {
    const { raceNumber, raceStatus } = race;
    const raceInformations = adaptRaceInformations(race);

    return {
      bet_reunion_id: `R${internalMeetingNumber}`,
      bet_course_id: `C${raceNumber}`,
      etat_course: raceStatus ? adaptRaceStatus(raceStatus) : '',
      discipline: raceInformations.gait === RaceGait.GALLOP ? 'galop' : 'trot',
    };
  }

  return {};
};

export { extractCourseData, extractUserData };
