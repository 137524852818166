import { useContext } from 'react';

import { Lang } from '@assets/locales';
import { Context as IntlWrapperContext } from '@components/IntlWrapper';

type UseLocaleReturn = {
  locale: Lang;
  handleChangeLang: (lang: Lang) => void;
};

const useLocale = (): UseLocaleReturn => {
  const { locale, changeLocale } = useContext(IntlWrapperContext);

  const handleChangeLang = (lang: Lang) => changeLocale(lang);

  return {
    locale,
    handleChangeLang,
  };
};

export { useLocale };
