const ROUTES = {
  HOME: '/',
  RACECARDS: '/racecards',
  MEETINGS: ':startDate/:meeting',
  RACES: ':startDate/:meeting/:race',
  QUINTE: '/quinte',
  NOT_FOUND: '/404',
  LOGIN: '/login',
  ACCOUNT: '/account',
  CGU_VALIDATION: '/cgu-validation',
  NON_RUNNERS: '/non-runners',
  RACING_EVENTS: '/racing-events',
};

export { ROUTES };
