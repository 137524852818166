import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { isEmpty } from 'lodash';

import { RequestResponse, isClientError, isServerError } from '@api/services/types/RequestStatus';

export interface AdaptRequestResponseProps<T> {
  data?: T;
  error: SerializedError | FetchBaseQueryError | undefined;
  isSuccess: boolean;
  isFetching: boolean;
  isError: boolean;
  isUninitialized: boolean;
}

const adaptRequestResponse = <T>({
  data,
  error,
  isSuccess,
  isFetching,
  isError,
  isUninitialized,
}: AdaptRequestResponseProps<T>): RequestResponse<T> => {
  return {
    status: {
      isError,
      isSuccess,
      isFetching,
      isServerError: isServerError(error),
      isClientError: isClientError(error),
      isSuccessWithData: isSuccess && !isFetching && !isEmpty(data),
      isSuccessWithNoData: isSuccess && !isFetching && isEmpty(data),
      isUninitialized,
    },
    data,
  };
};

export { adaptRequestResponse };
