/* istanbul ignore file */

import { RootState } from '../..';

import { sliceCreator } from './sliceCreator';

export const {
  actions: { update: updateMeetingEvent },
  reducer: meetingEventsReducer,
} = sliceCreator('meetingEvents');
export const getMeetingEvents = (state: RootState) => state.meetingEvents;

export const {
  actions: { update: updateRaceEvent },
  reducer: raceEventsReducer,
} = sliceCreator('raceEvents');
export const getRaceEvents = (state: RootState) => state.raceEvents;
