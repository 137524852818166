import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';

import { TAG_EVENTS } from '@config/tags';
import { EVENTS } from '@config/urls';

import { Event } from '../types';

import { baseQuery } from './baseQuery';

export type EventsArgs = {
  date: string;
  racecourses?: string[];
  raceIds?: string[];
  eventTypes?: string[];
  pageSize?: string;
  id?: string;
  eventRangeKey?: string;
};

export type EventResponse = {
  events: Event[];
  isLastPage: boolean;
};

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  tagTypes: [TAG_EVENTS],
  baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getEvents: builder.query<{ events: Event[]; isLastPage: boolean }, EventsArgs>({
      query: ({ date, racecourses, raceIds, eventTypes, pageSize, id, eventRangeKey }) => ({
        url: `${EVENTS}/${date}`,
        params: { racecourses, raceIds, eventTypes, pageSize, id, eventRangeKey },
      }),
      transformResponse(events: Event[], meta: FetchBaseQueryMeta) {
        const isLastPage = meta.response?.headers.get('eventrangekey') === null;

        return { events, isLastPage };
      },
    }),
  }),
});

export const { useGetEventsQuery, useLazyGetEventsQuery } = eventsApi;
