import { TornadoTypes } from '@pmu/tornado';

import { BaseRace } from '@api/types/baseRace';

import { Audience, DisciplineCode, Meeting, SpecialtyCode, TimeOfTheDayCode } from '../types';

import type { HdeSchemas } from '../types/generated/apiSchemas';

type meetingWithNonNullableNumber = Omit<Meeting, 'meetingNumber'> & {
  meetingNumber: NonNullable<Meeting['meetingNumber']>;
};

const sortMeetings = (meetings: Meeting[] | undefined): Meeting[] => {
  if (meetings === undefined) {
    return [];
  }
  const meetingNumberIsNotNull = (item: Meeting): item is meetingWithNonNullableNumber =>
    item.meetingNumber !== null;
  const meetingNumberIsNull = (item: Meeting) => item.meetingNumber === null;

  const ascendingMeetingNumber = (
    a: meetingWithNonNullableNumber,
    b: meetingWithNonNullableNumber
  ) => a.meetingNumber - b.meetingNumber;

  const meetingsSorted = meetings.filter(meetingNumberIsNotNull).sort(ascendingMeetingNumber);
  const meetingsWithNullNumber = meetings.filter(meetingNumberIsNull);

  return [...meetingsSorted, ...meetingsWithNullNumber];
};

const ascendingRaceNumber = (a: BaseRace, b: BaseRace) => a.raceNumber - b.raceNumber;

const adaptMeeting = (meeting: HdeSchemas['Meeting']): Meeting => {
  const { racecourse, specialty, timeOfTheDay, races, audiences, ...meetingOtherProps } = meeting;

  return {
    ...meetingOtherProps,
    racecourse: {
      ...racecourse,
      countryCode: racecourse.countryCode as TornadoTypes.IsoCode,
    },
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    specialty: (specialty?.code as SpecialtyCode) ?? null,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    timeOfTheDay: (timeOfTheDay?.code as TimeOfTheDayCode) ?? null,
    races: races
      .map((race) => {
        const { discipline, ...raceOtherProps } = race;

        return {
          ...raceOtherProps,
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          discipline: (discipline?.code as DisciplineCode) ?? null,
        };
      })
      .sort(ascendingRaceNumber),
    audiences: !audiences
      ? []
      : (audiences.filter((a) => a !== 'UNKNOWN' && a !== 'INTERNATIONAL') as Audience[]),
  };
};

const adaptMeetings = (meetings: HdeSchemas['Meeting'][]): Meeting[] => meetings.map(adaptMeeting);

export { adaptMeetings, adaptMeeting, sortMeetings };
