import { CookiesConfig, CookiesToggle } from '@components/CookiesModal/config';

export const createCookieState = (config: CookiesConfig, accept: boolean): CookiesToggle => {
  const obj: CookiesToggle = {};
  Object.keys(config).forEach((key) => {
    obj[key] = accept;
  });

  return obj;
};
