import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

export interface AuthState {
  isAuth: boolean;
}

const initialState: AuthState = {
  isAuth: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, { payload }) => {
      return {
        isAuth: payload,
      };
    },
  },
});

export const { setAuth } = authSlice.actions;

export const getAuth = (state: RootState) => state.auth;

export const { reducer: authReducer } = authSlice;
