import React from 'react';
import classNames from 'classnames';
import { Text, TranslatedText } from '@pmu/tornado';

import { isUserAuthenticatedWithAcceptedCGUs as isUserAuthenticatedWithAcceptedCGUsSelector } from '@api/services/user';
import { S3_ASSETS_URL } from '@config/urls';
import { useAppSelector } from '@store/hooks';

import { version } from '../../../../../package.json';
import './index.scss';

const legalNoticeURL = `${S3_ASSETS_URL}/pdf/CGU_infocentre_FR_EN.pdf`;
const cookiesNoticeURL = `${S3_ASSETS_URL}/pdf/Cookie_management_policy_EN_FR.pdf`;
const privacyPolicyURL = `${S3_ASSETS_URL}/pdf/Privacy_policy_infocentre_EN_FR.pdf`;

const Footer = () => {
  const isUserAuthenticatedWithAcceptedCGUs = useAppSelector(
    isUserAuthenticatedWithAcceptedCGUsSelector
  );

  const cls = classNames({
    'is-notAuth': !isUserAuthenticatedWithAcceptedCGUs,
  });

  return (
    <footer {...(cls && { className: cls })}>
      <a target="_blank" rel="noreferrer" href="https://www.pmu.fr">
        <img
          src={`/logo-PMU-${isUserAuthenticatedWithAcceptedCGUs ? 'color' : 'white'}.svg`}
          alt="PMU.fr"
          width="90"
        />
      </a>

      <Text size="m">
        v{version}
        {window.ticketIdForDeployment}
      </Text>

      <a target="_blank" rel="noreferrer" href={legalNoticeURL}>
        <TranslatedText id="app.generic.legalNotice" />
      </a>
      <a target="_blank" rel="noreferrer" href={privacyPolicyURL}>
        <TranslatedText id="app.generic.privacyPolicy" />
      </a>
      <a target="_blank" rel="noreferrer" href={cookiesNoticeURL}>
        <TranslatedText id="app.generic.cookiesManagement" />
      </a>
    </footer>
  );
};

export { Footer };
