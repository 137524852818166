export const cookieConfirmEvent = 'cookie-confirm';

const subscribe = (eventName: string, listener: (e: CustomEvent) => void) => {
  document.addEventListener(eventName, listener as EventListener);
};

const unsubscribe = (eventName: string, listener: (e: CustomEvent) => void) => {
  document.removeEventListener(eventName, listener as EventListener);
};

const publish = <T = unknown>(eventName: string, data: T) => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};

export { publish, subscribe, unsubscribe };
