import { useEffect } from 'react';

import { cookiesName, CookiesToggle } from '@components/CookiesModal/config';
import { pushTagGTM } from '@services/GTM';
import { cookieConfirmEvent, subscribe, unsubscribe } from '@utils/event';

import {
  initialize as initializeGTM,
  platformSpecificMethods,
  queue,
  runtime,
} from '../services/SendTag';

const initializeAndProcessQueue = () => {
  initializeGTM({ pushTagGTM });
  queue.forEach((preparedData) => {
    platformSpecificMethods.pushTagGTM({
      ...preparedData,
      ...runtime.userData,
    });
  });
  queue.length = 0;
};

const handleCookies = (cookiesToggle: CookiesToggle) => {
  Object.keys(cookiesToggle).forEach((cookieName) => {
    if (cookieName !== cookiesName.analytics) {
      return;
    }

    const isEnabled = cookiesToggle[cookieName];
    runtime.ready = isEnabled;
    if (isEnabled) {
      initializeAndProcessQueue();
    }
  });
};

const useGTM = () => {
  useEffect(() => {
    const listener = (e: CustomEvent<CookiesToggle>) => {
      const cookiesToggle = e.detail;
      handleCookies(cookiesToggle);
    };

    subscribe(cookieConfirmEvent, listener);

    return () => {
      unsubscribe(cookieConfirmEvent, listener);
    };
  }, []);
};

export { useGTM };
