import { HdeSchemas } from '@api/types/generated/apiSchemas';
import { OddsFinal } from '@api/types/oddsFinal';

type FinalDividends = HdeSchemas['FinalDividends'];

const adaptOddsFinal = (report: FinalDividends): OddsFinal => {
  const { data, racecourse, ...reportProps } = report;

  return {
    ...reportProps,
    racecourseCode: racecourse.code,
    data:
      data?.map(({ combination, ...datum }) => ({
        ...datum,
        combination: combination ?? [],
      })) ?? [],
  };
};

export { adaptOddsFinal };
