/* istanbul ignore file */
import React from 'react';
import loadable from '@loadable/component';

const Login = loadable(() => import('@features/Login'), {
  resolveComponent: (components) => components.Login,
  fallback: <div>Loading Login ...</div>,
});

const CguValidation = loadable(() => import('@features/CguValidation'), {
  resolveComponent: (components) => components.CguValidation,
  fallback: <div>Loading CguValidation ...</div>,
});

const Account = loadable(() => import('@features/Account'), {
  resolveComponent: (components) => components.Account,
  fallback: <div>Loading Account ...</div>,
});

const Home = loadable(() => import('@features/Home'), {
  resolveComponent: (components) => components.Home,
  fallback: <div>Loading Home ...</div>,
});

const Racecards = loadable(() => import('@features/Racecards'), {
  resolveComponent: (components) => components.Racecards,
  fallback: <div>Loading Programme ...</div>,
});

const Meeting = loadable(() => import('@features/Meeting'), {
  resolveComponent: (components) => components.Meeting,
  fallback: <div>Loading Reunions ...</div>,
});

const QuintePlus = loadable(() => import('@features/QuintePlus'), {
  resolveComponent: (components) => components.QuintePlus,
  fallback: <div>Loading Quinte+...</div>,
});

const Race = loadable(() => import('@features/Race'), {
  resolveComponent: (components) => components.Race,
  fallback: <div>Loading Races ...</div>,
});

const NonRunners = loadable(() => import('@features/NonRunners'), {
  resolveComponent: (components) => components.NonRunners,
  fallback: <div>Loading Non runners ...</div>,
});

const RacingEvents = loadable(() => import('@features/RacingEvents'), {
  resolveComponent: (components) => components.RacingEvents,
  fallback: <div>Loading racing events ...</div>,
});

const NotFound = loadable(() => import('@features/NotFound'), {
  resolveComponent: (components) => components.NotFound,
  fallback: <div>Loading 404...</div>,
});

export {
  Login,
  CguValidation,
  Account,
  Home,
  Racecards,
  Meeting,
  QuintePlus,
  Race,
  NonRunners,
  RacingEvents,
  NotFound,
};
