import React, { useLayoutEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import classNames from 'classnames';

import { isUserAuthenticatedWithAcceptedCGUs as isUserAuthenticatedWithAcceptedCGUsSelector } from '@api/services';
import { GrantAccess } from '@components/GrantAccess/GrantAccess';
import { useGrantAccess } from '@components/GrantAccess/logic/useGrantAccess';
import { Header } from '@components/Layout/components/Header';
import { useAppSelector } from '@store/hooks';

import { Footer } from '../components/Footer';
import { SidebarMenu } from '../components/SidebarMenu';
import './index.scss';

const CommonLayout = () => {
  const [namespace, setNamespace] = useState('');
  const isUserAuthenticatedWithAcceptedCGUs = useAppSelector(
    isUserAuthenticatedWithAcceptedCGUsSelector
  );
  const showSidebar = useGrantAccess({ resourceId: 'app.layout.sidebar' });

  const mainCls = classNames('layout', namespace && `layout--${namespace}`, {
    'is-notAuth': !isUserAuthenticatedWithAcceptedCGUs,
    'is-notSidebar': !showSidebar,
  });

  return (
    <>
      <Header />
      <main className={mainCls}>
        {isUserAuthenticatedWithAcceptedCGUs ? (
          <GrantAccess resourceId="app.layout.sidebar">
            <SidebarMenu />
          </GrantAccess>
        ) : null}

        <article>
          <Outlet context={{ namespace, setNamespace, noSidebar: !showSidebar }} />
          <Footer />
        </article>
      </main>
    </>
  );
};

type LayoutNameSpace = 'App' | 'Account' | 'Login';
type ContextType = {
  namespace: LayoutNameSpace;
  noSidebar: boolean;
  /**
   * @private
   * Allows to set the layout namespace on useLayout() first call by passing it an authorized value
   * should not being reused after from useOutletContext()!
   */
  setNamespace: React.Dispatch<React.SetStateAction<string>>;
};

const useLayout = (namespace?: LayoutNameSpace): Omit<ContextType, 'setNamespace'> => {
  const { setNamespace, ...outletContext } = useOutletContext<ContextType>();

  useLayoutEffect(() => {
    if (namespace && outletContext.namespace !== namespace) {
      // eslint-disable-next-line no-underscore-dangle
      setNamespace(namespace);
    }
  }, [namespace]);

  return outletContext;
};

export { CommonLayout, useLayout };
