import tornadoEn from '@pmu/tornado/dist/i18n/en.json';
import tornadoFr from '@pmu/tornado/dist/i18n/fr.json';

import en from './en.json';
import fr from './fr.json';

export type Lang = 'fr' | 'en';
export type Translation = Record<string, string>;

type Translations = {
  [key in Lang]: Translation;
};

const langs: Translations = {
  en: { ...en, ...tornadoEn },
  fr: { ...fr, ...tornadoFr },
};

// eslint-disable-next-line import/no-default-export
export default langs;
