import React from 'react';
import classNames from 'classnames';
import { Form, SecondaryCTA, Text, TranslatedText } from '@pmu/tornado';

import { getUser } from '@api/services';
import { User } from '@api/types';
import { FEEDBACK_URL } from '@config/urls';
import { extractUserData } from '@services/helpers';
import { sendTag } from '@services/SendTag';
import { useAppSelector } from '@store/hooks';

import './index.scss';

const SidebarMenuItemFeedback = ({ ...restProps }) => {
  const user = useAppSelector<User>(getUser as () => User);

  const payloadForGTM = extractUserData(user);
  // ici on imite le comportement interne du SidebarMenuItem
  // eslint-disable-next-line no-underscore-dangle
  const isSibebarOpen = (restProps as { _open: 'true' | 'false' })._open === 'true';

  const mainCls = classNames('layout-sideBarMenu-SidebarMenuItemFeedback', {
    'layout-sideBarMenu-SidebarMenuItemFeedback--with-background': isSibebarOpen,
  });

  const color = isSibebarOpen ? 'gray-100' : 'gray-500';

  const sendTagAndOpenFeedbackURL = () => {
    sendTag('infocentre.clic.noter_app', payloadForGTM);
    window.open(FEEDBACK_URL, '_blank');
  };

  return (
    <div className={mainCls} data-testid="infocentre/sidebarmenuitemfeedback">
      <Form iconStyle="layout-sideBarMenu-SidebarMenuItemFeedback-icon" color={color} />
      <div>
        <Text
          size="m"
          color="white-primary"
          className="layout-sideBarMenu-SidebarMenuItemFeedback-title"
        >
          <TranslatedText id="app.sidebar.feedback.text" />
        </Text>
        <SecondaryCTA onClick={sendTagAndOpenFeedbackURL}>
          <Text size="sm" color="green-accent" className="title" type="bold">
            <TranslatedText id="app.sidebar.feedback.CTA" />
          </Text>
        </SecondaryCTA>
      </div>
    </div>
  );
};

export { SidebarMenuItemFeedback };
