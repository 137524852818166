import { GTMData } from '../SendTag';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }
}

// eslint-disable-next-line consistent-return
export const pushTagGTM = (data: GTMData) => {
  if (!window.dataLayer) {
    // TODO: Dynatrace or an other logger service should catch this error
    // eslint-disable-next-line no-console
    return console.error('No GTM dataLayer present, tag is not sent');
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.dataLayer.push((dataLayer: any) => {
    dataLayer.reset();
  });
  window.dataLayer.push(data);
};
